<template>
  <div>
    <window-header></window-header>
    <nav style="justify-content: space-between;">
      <button
        class="button button-fill"
        :class="{disabled: activeTab==0}"
        @click="tabDisabled(($refs['main-tabs'].activeIndex-1)+$refs['main-tabs'].buttons.length%$refs['main-tabs'].buttons.length)? null : $refs['main-tabs'].prevTab()"
      >
        <fa-icon :icon="['fas', 'chevron-left']" />
        <span>{{$t('back')}}</span>
      </button>
      <button
        class="button button-fill"
        @click="tabDisabled(($refs['main-tabs'].activeIndex+1)%$refs['main-tabs'].buttons.length)? null : $refs['main-tabs'].nextTab()"
        v-if="activeTab<2"
      >
        <span>{{$t('next')}}</span>
        <fa-icon :icon="['fas', 'chevron-right']" />
      </button>
    </nav>
    <section>
      <article style="height:436px; width: 675px;">
        <r-tabs
          ref="main-tabs"
          :wt="formData.wt"
          style="top:0px; left:0px; height:435px;"
          :buttons="[
            '1. Cliente',
            '2. Documentos',
            '3. Líneas'
          ]"
          :classButton="function(i){return {disabled: tabDisabled(i)}}"
          :styleButton="'width:33%;'"
          @active="onActiveTab"
        >
          <div class="tab">
            <fieldset
              style="position: relative; left: 80px; top: 80px; width: 480px; height: 200px"
              model="devolucion_condicional_venta"
            >
              <legend>Seleccione cliente</legend>
              <field
                name="cliente_id"
                widget="m2o"
                labelProp="nombre_comercial"
                width="320px"
                style="top:47px; left:70px;"
              />
              <field
                name="devoluciones"
                widget="checkbox"
                label="Devoluciones"
                reverse
                inline-label
                style="top:70px; left:70px;"
              />
              <field
                name="notas_devoluciones"
                widget="checkbox"
                label="Prop. de Devoluciones"
                reverse
                inline-label
                style="top:85px; left:70px;"
                v-if="showWt"
              />
              <field
                name="desde"
                type="date"
                label="Desde"
                width="100px"
                inline-label
                style="top:86px; right:50px;"
              />
              <field
                name="hasta"
                type="date"
                label="Hasta"
                width="100px"
                inline-label
                style="top:106px; right:50px;"
              />
            </fieldset>
          </div>
          <div class="tab">
            <field
              ref="documentos_pendientes"
              name="documentos_pendientes"
              widget="handsontable"
              :height="360"
              :width="650"
              :minRows="18"
              style="top:30px; left:4px; width: 653px;"
              customprop="docs_customprop"
              :columns="[
                {name:'sel',header:'Sel.', widget: 'checkbox', help: 'Seleccionado'},
                {name:'tipo_doc',header:'T',readOnly:true, renderer:wtRenderer},
                {name:'ndoc',header:'NºDocumento',readOnly:true},
                {name:'cliente_id',header:'Cliente',readOnly:true,type:'m2o',primary:'codigo',label:'nombre_comercial',showCode:true},
                {name:'fecha',type:'date2',header:'Fecha',readOnly:true},
                {name:'total',header:'Total',readOnly:true,type:'numeric',numericFormat: { pattern: '0,0.00'}},
                {name:'divisa_id',header:'Divisa',readOnly:true,type:'m2o',primary:'codigo',label:'nombre',showCode:true},
                {name:'cambio',header:'Cambio',readOnly:true,type:'numeric',numericFormat: { pattern: '0,0.00'}},
              ]"
            />
            <span style="position:absolute; top:5px; left:5px;">
              <button name="sel_all_docs" v-tooltip="'Seleccionar todos'" @click="sellAllDocs">
                <fa-icon :icon="['far', 'check-square']" />
              </button>
              <button
                name="dessel_all_docs"
                v-tooltip="'Desseleccionar todos'"
                @click="desselAllDocs"
              >
                <fa-icon :icon="['far', 'square']" />
              </button>
            </span>
          </div>
          <div class="tab">
            <field
              ref="lineas_pendientes"
              name="lineas_pendientes"
              widget="handsontable"
              label="Líneas"
              :height="380"
              :width="650"
              :minRows="18"
              style="top:5px; left:4px; width: 653px;"
              customprop="lines_customprop"
              readonly
              :htSettings="{columnSorting: true}"
              :columns="[
                {name:'ean13',header:'||||||||',readOnly:true},
                {name:'articulo_id',header:'Artículo',readOnly:true, type: 'm2o', primary:'codigo', label:'codigo', noOpen: true, fields:[{name:'thumbnail',fields:['imagen']},'tarifa1','tarifa2','tarifa3','tarifa4','tarifa5','tarifa6','tarifa7','tarifa8','tarifa9','tarifa10']},
                {name:'descripcion',header:'Descripción',readOnly:true},
                {name:'modelo_id',header:'Modelo',type:'m2o', primary:'codigo', label:'codigo', readOnly:true},
                {name:'nota',header:'Nota',readOnly:true},
                {name:'tt',header:'T', type:'select', options:['A','R']},
                {name:'almacen_id',header:'Almacén',readOnly:true, type:'m2o', primary:'codigo', readOnly: true},
                {name:'unidades_pendientes',header:'Unid. pendientes',readOnly:true,type:'numeric',numericFormat: { pattern: '0,0.00'}},
                {name:'peso_pendiente',header:'Peso pendiente',readOnly:true,type:'numeric',numericFormat: { pattern: '0,0.000'}},
                {name:'unidades',header:'Unid.',type:'numeric',numericFormat: { pattern: '0,0.00'}},
                {name:'peso',header:'Peso',type:'numeric',numericFormat: { pattern: '0,0.000'}},
                {name:'v',header:'V',readOnly:true, type:'select', options:['U','P']},
                {name:'m',header:'M',readOnly:true, type:'checkbox'},
                {name:'r',header:'R',readOnly:true, type:'checkbox'},

                {name:'precio_coste',header:'P. Coste',readOnly:true,type:'numeric' ,numericFormat: { pattern: '0,0.00'}},
                {name:'tt2',header:'T',readOnly:true, type:'select', options:['1','2','3','4','5','6','7','8','9','10']},
                {name:'precio',header:'Precio',readOnly:true,type:'numeric' ,numericFormat: { pattern: '0,0.00'}},
                {name:'descuentos',header:'Dtos.',readOnly:true,type:'numeric',numericFormat: { pattern: '0,0.00'}, sufix: '%'},
                {name:'descuento_peso',header:'Dto./peso',readOnly:true,type:'numeric',numericFormat: { pattern: '0,0.00'}, sufix: '%'},
                {name:'totallinea',header:'Total Línea',readOnly:true,type:'numeric',numericFormat: { pattern: '0,0.00'}},
                {name:'m2',header:'M',readOnly:true, type:'checkbox'},
                {name:'c2',header:'C',readOnly:true, type:'checkbox'},
                {name:'p',header:'P',readOnly:true, type:'checkbox'},
                {name:'u',header:'U',readOnly:true, type:'checkbox'},
                {name:'com',header:'Com.',readOnly:true, type:'m2o', label:'codigo', primary:'codigo', emptyOnError: true},
                {name:'c',header:'C.',readOnly:true, type:'select', options:['D','I']},
                {name:'margen',header:'%',readOnly:true,type:'numeric',numericFormat: { pattern: '0,0.00'}, sufix: '%'},
                {name:'pventa',header:'PVP',readOnly:true,type:'numeric' ,numericFormat: { pattern: '0,0.00'}},
                {name:'e',header:'E',readOnly:true,type:'checkbox'},
              ]"
            />
          </div>
        </r-tabs>
      </article>
    </section>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import Handsontable from "handsontable";
var floatOrZero = function(val) {
  return isFinite(parseFloat(val || 0.0)) ? parseFloat(val || 0.0) : 0.0;
};
export default {
  mixins: [WindowMixin, rFormMixin],
  data: function() {
    return {
      title: "Consulta de devolución de condicional de venta",
      dbAdapter: "devolucion_condicional_venta",
      primary: "ndoc",
      mode: "new",
      showWt: false,
      original_documentos_pendientes: [],
      original_lineas_pendientes: [],
      activeTab: 0
      /*sequence: { name: "almacen" }*/
    };
  },
  methods: {
    wtRenderer: function(instance, td, row, col, prop, value, cellProperties) {
      var self = this;
      if (!self.$refs.documentos_pendientes.field) return;
      var h = self.$refs.documentos_pendientes.field.hotInstance;
      var wt = h.getSourceData()[row]["wt"];
      if (parseInt(wt)) {
        td.style.color = "black!important";
        //window.$(td).addClass("plum");
        td.style.background = "antiquewhite";
      }
      Handsontable.renderers.TextRenderer.apply(this, arguments);
    },
    docsSelected() {
      var self = this;
      return self.formData.documentos_pendientes
        ? self.formData.documentos_pendientes.filter(doc => parseInt(doc.sel))
        : [];
    },
    lineasSelected() {
      var self = this;
      return self.formData.lineas_pendientes
        ? self.formData.lineas_pendientes.filter(doc => parseInt(doc.sel))
        : [];
    },
    onActiveTab(i) {
      var self = this;
      self.activeTab = i;
      switch (i) {
        case 1:
          var p = Promise.resolve();
          var tts = {
            devoluciones: {
              model: "devolucion_condicional_venta",
              wt: 0,
              tipo_doc: "DEVOLUCIÓN"
            },
            notas_devoluciones: {
              model: "devolucion_condicional_venta",
              wt: 1,
              tipo_doc: "DEVOLUCIÓN"
            }
          };
          self.$set(self.formData, "documentos_pendientes", []);
          self.original_documentos_pendientes = [];
          for (let tt in tts) {
            if (self.formData[tt]) {
              p = p.then(
                _ =>
                  new Promise(function(resolve, reject) {
                    let filter = [
                      ["cliente_id", "=", self.formData.cliente_id.codigo],
                      ["wt", "=", tts[tt].wt]
                    ];
                    if (self.formData.desde)
                      filter.push(["fecha", ">=", self.formData.desde]);
                    if (self.formData.hasta)
                      filter.push(["fecha", "<=", self.formData.hasta]);
                    window.DB.getList(tts[tt].model, {
                      filter,
                      fields: [
                        "ndoc",
                        "wt",
                        { name: "cliente_id", fields: ["nombre_comercial"] },
                        "fecha",
                        "total",
                        { name: "divisa_id", fields: ["nombre"] },
                        "cambio",
                        {
                          name: "lineas",
                          fields: [
                            {
                              name: "articulo_id",
                              fields: [
                                "codigo",
                                "ean13",
                                {
                                  name: "modelo_id",
                                  fields: ["codigo", "nombre"]
                                },
                                {
                                  name: "familia_id",
                                  fields: ["codigo", "nombre"]
                                },
                                {
                                  name: "tipo_comision_id",
                                  fields: ["codigo", "descripcion"]
                                },
                                "nombre",
                                "unidad_medida_compra",
                                "compra_metal_incluido",
                                "recargo_equivalencia",
                                "incluir_contraste_pcoste",
                                "incluir_ocostes_pcoste",
                                "precio_coste",
                                "unidad_medida_venta",
                                "venta_metal_incluido",
                                "peso_unitario_fino",
                                "margen_ultima_compra",
                                {
                                  name: "taqueria_ids",
                                  fields: [
                                    "codigo",
                                    "nombre",
                                    { name: "thumbnail", fields: ["imagen"] },
                                    "precio_coste"
                                  ]
                                },
                                "taqueria_precio",
                                { name: "thumbnail", fields: ["imagen"] },
                                "tarifa1",
                                "tarifa2",
                                "tarifa3",
                                "tarifa4",
                                "tarifa5",
                                "tarifa6",
                                "tarifa7",
                                "tarifa8",
                                "tarifa9",
                                "tarifa10"
                              ]
                            },
                            "ean13",
                            { name: "modelo_id", fields: ["codigo", "nombre"] },
                            "descripcion",
                            {
                              name: "almacen_id",
                              fields: ["codigo", "nombre"]
                            },
                            "nota",
                            "tt",
                            "unidades",
                            "etiqueta",
                            "peso",
                            "mpl",
                            {
                              name: "taqueria_ids",
                              fields: [
                                "codigo",
                                "nombre",
                                { name: "thumbnail", fields: ["imagen"] },
                                "precio_coste"
                              ]
                            },
                            "incluir_taqueria",
                            "taqueria_precio",
                            "v",
                            "m",
                            "r",
                            "tt2",
                            "precio",
                            "descuentos",
                            "descuento_peso",
                            "m2",
                            "c2",
                            "p",
                            "u",
                            {
                              name: "com",
                              fields: ["codigo", "descripcion"]
                            },
                            "c",
                            "margen",
                            "pventa",
                            "e",
                            "precio_coste",
                            "totallinea",
                            { name: "origin_pedido_id", fields: ["id"] },
                            "unidades_facturadas",
                            "peso_facturado"
                          ]
                        }
                      ]
                    })
                      .then(function(res) {
                        res.data.forEach(function(doc) {
                          doc.tipo_doc = tts[tt].tipo_doc;
                          self.original_documentos_pendientes.push(
                            JSON.parse(JSON.stringify(doc))
                          );
                          delete doc.lineas;
                          self.formData.documentos_pendientes.push(doc);
                        });
                        resolve();
                      })
                      .catch(function() {
                        reject();
                      });
                  })
              );
            }
          }
          p.then(function() {
            self.$forceUpdate();
          }).catch(function() {
            window.console.log("eeeeeer");
          });
          break;
        case 2:
          self.$set(self.formData, "lineas_pendientes", []);
          self.original_lineas_pendientes = [];
          self.formData.documentos_pendientes.forEach(function(doc, i) {
            if (!parseInt(doc.sel)) return;
            //TODO: origin
            self.original_documentos_pendientes[i].lineas.forEach(function(
              linea
            ) {
              linea.unidades_pendientes =
                parseInt(linea.unidades || 0) -
                parseInt(linea.unidades_facturadas || 0);
              linea.peso_pendiente =
                parseInt(linea.peso || 0) - parseInt(linea.peso_facturado || 0);
              linea.unidades = linea.unidades_pendientes;
              linea.peso = linea.peso_pendiente;
              self.formData.lineas_pendientes.push(linea);
              let linea2 = JSON.parse(JSON.stringify(linea));
              if (doc.tipo_doc == "PEDIDO") linea2.origin_pedido_id = linea2.id;
              else linea2.origin_albaran_id = linea2.id;
              delete linea2.id;
              delete linea2.nota;
              self.original_lineas_pendientes.push(linea2);
            });
          });
          self.$forceUpdate();
      }
    },
    sellAllDocs() {
      var self = this;
      var h = self.$refs.documentos_pendientes.field.hotInstance;
      window.$.each(h.getSourceData(), function(i, o) {
        if (!h.isEmptyRow(i)) {
          h.setDataAtRowProp(i, "sel", 1, "sel_all"); //o.sel = 1;
        }
      });
      h.render();
    },
    desselAllDocs() {
      var self = this;
      var h = self.$refs.documentos_pendientes.field.hotInstance;
      window.$.each(h.getSourceData(), function(i, o) {
        if (!h.isEmptyRow(i)) {
          h.setDataAtRowProp(i, "sel", 0, "sel_all"); //o.sel = 1;
        }
      });
      h.render();
    },
    sellAllLines() {
      var self = this;
      var h = self.$refs.lineas_pendientes.field.hotInstance;
      window.$.each(h.getSourceData(), function(i, o) {
        if (!h.isEmptyRow(i)) {
          h.setDataAtRowProp(i, "sel", 1, "sel_all"); //o.sel = 1;
        }
      });
      h.render();
    },
    desselAllLines() {
      var self = this;
      var h = self.$refs.lineas_pendientes.field.hotInstance;
      window.$.each(h.getSourceData(), function(i, o) {
        if (!h.isEmptyRow(i)) {
          h.setDataAtRowProp(i, "sel", 0, "sel_all"); //o.sel = 1;
        }
      });
      h.render();
    },
    tabDisabled(i) {
      var self = this;
      switch (i) {
        case 1:
          return (
            !self.formData.cliente_id ||
            (!self.formData.devoluciones && !self.formData.notas_devoluciones)
          );
        case 2:
          return !self.docsSelected().length;
      }
    },
    onChange(arrProps, callback) {
      this.$on("change", function(prop, source) {
        if (this.mode != "new" && this.mode != "edit") return;
        if (!arrProps.includes(prop)) return;
        callback(source);
      });
    }
  },
  mounted() {
    var self = this;
    self.$refs["main-tabs"].$refs.toolbar.addEventListener("dblclick", function(
      e
    ) {
      if (self.app.session.mode == "a") return;
      if (e.target == self.$refs["main-tabs"].$refs.toolbar) {
        if (self.mode != "edit" && self.mode != "new" && self.mode != "search")
          return;
        self.showWt = true;
        self.$set(self.formData, "wt", !parseInt(self.formData.wt) ? 1 : 0);
        self.$emit("change", "wt", "edit");
        if (self.mode == "search") self.setFilter(self.getFilter());
      }
    });
  }
};
</script>
<style>
tr.wtdoc > td {
  background: #72dad7 !important;
  color: white !important;
}
</style>